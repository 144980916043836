import Header from "./components/header/header"
import Announcement from "./components/header/announcement"

export default function Informatii () {
    return (
        <div>
            <Announcement/>
            <Header page="informatii"/>
            <div className="min-h-screen bg-products py-8">
                <div className="  m-auto rounded-lg bg-white w-[90%] flex flex-col p-8">
                    <div className="m-auto gap-8 flex flex-col lg:flex-row md:w-[80%] xl:w-[60%]">
                        <div className="hover:text-white hover:bg-black duration-200 border-gray-200 border-2 rounded-xl p-6 flex flex-col text-justify mx-auto gap-4 w-full md:w-[80%] xl:w-[60%]">
                            <div className="ml-0 text-left text-xl pb-2 border-b-2">
                                <span className="font-bold">Informații Site</span>
                            </div>
                                <p className="text-sm md:text-base">
                                Site-ul Stockzilla nu este un magazin on-line. Este doar pentru informarea ta. <br/><br/>
                                Aici găsești informații cum ar fi: prețul actual al produsului, detalii despre produs și poze suplimentare. <br/><br/>
                                Toate produsele prezente pe site se află pe stoc – unele sunt deja în magazin, altele în depozit.  <br/><br/>
                                Magazinul este aprovizionat zilnic cu marfa nouă. <br/><br/>
                                </p>
                        </div>

                        <div className="hover:text-white hover:bg-black duration-200 border-gray-200 border-2 rounded-xl p-6 flex flex-col text-justify mx-auto gap-4 w-full md:w-[80%] xl:w-[60%]">
                            <div className="ml-0 text-left text-xl pb-2 border-b-2">
                                <span className="font-bold">Informații Produse</span>
                            </div>
                                <p className="text-sm md:text-base">
                                Produsele vândute în magazinul Stockzilla, fiind provenite în majoritatea lor din retururi, pot avea mici defecte estetice, le pot lipsi accesorii sau pot fi defecte. <br/><br/>
                                Pentru că dorim să devenim un partener de încredere, în magazinul Stockzilla vei găsi un banc de probe unde angajații noștri te ajută să desfaci cutia, să analizezi și să probezi produsul, pentru a te asigura că acesta îndeplinește toate nevoile tale.  <br/><br/>
                                Aici poți constata daca prezintă defecte estetice sau de funcționalitate precum și lipsa unor accesorii. <br/><br/>
                                Produsele se vând așa cum sunt, în starea în care sunt. <br/><br/>
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
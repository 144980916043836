import ProductContent from './components/products/product-content';
import Header from './components/header/header';
import Announcement from './components/header/announcement';


export default function Product (args) {
  return (
    <div>
      <Announcement/>
      <Header/>
      <ProductContent productPath={args.productPath}/>
      <div className="bg-black w-full flex p-2">
                <span className="text-white text-sm items-center m-auto">Copyright © 2024 Crangeio S.R.L.</span>
            </div>
    </div>
  )
}

/* 

<section className="page-body">
          <div className="website-title-container">
            <a className="website-title" href={"/"}>Stockzilla</a>
          </div>
          <a href={document.referrer} class="back-button"> {"< Înapoi"} </a>
          <section className="product-page">
              <section class="product-left">
                {
                  obj.images.map(img => {
                    return(
                      <img src={img.large} alt="img"></img>
                    )
                  })
                }
                </section>
                <section class="product-right">
                  <span className="product-title"> {obj.title} </span>
                  <div className = "price-section">
                    <div className="newPrice">
                      {(((parseFloat(obj.price) * 4.9) * 50)/100).toFixed(2)} RON
                    </div>
                    <div className="oldPrice">
                      {(parseFloat(obj.price) * 4.9).toFixed(2)} RON
                    </div> 
                  </div>
                  <div className="product-description">{obj.description}</div>
                </section>
            </section>
          </section>
*/
import { useEffect, useState } from "react";
import ImageView from "./image-viewer";




export default function ProductContent(args) {

    const queryParameters = new URLSearchParams(window.location.search);

    const [csv, loadCsv] = useState([]);
    //const [obj, loadObject] = useState([]);
    

    const [isLoading, setLoading] = useState(true);
    //const [exists, setExists] = useState(true);

    var id = queryParameters.get("id");

    async function getProduct() {
        const res = await fetch(args.productPath);
        try {loadCsv(await res.json());
        
        } catch (e) {
            //setExists(false);
        }
        //loadObject(csv.filter(item => (item.lpn === id))[0]);
        //await console.log(obj);
        await setLoading(false);

    }

    useEffect(() => {
            getProduct();
    }, []) 

    if (!isLoading) {
        var obj = csv.filter(item => (item.lpn === id))[0];
        if(obj != null) {
            return (
                <div className=" bg-products w-full py-8">
                    <div className=" m-auto rounded-lg bg-white w-[90%] flex flex-col p-8">

                            { ((!obj.stoc) && 
                                <div className="mx-auto mb-8 p-2 px-4 md:w-1/2 flex flex-col text-center rounded-md bg-red-100 ring-1 ring-red-500">
                                    <span className="m-auto text-red-500 text-sm md:text-base">Produsul a fost vândut.</span>
                                </div>)

                            ||

                            ((obj.magazin) &&
                                <div className="mx-auto mb-8 p-2 px-4 md:w-1/2 flex flex-col text-center rounded-md bg-green-200 ring-1 ring-green-700">
                                    <span className="m-auto text-green-700 text-sm md:text-base">Produsul este disponibil fizic in magazin.</span>
                                </div>)

                            ||
                            
                            ((!obj.magazin) &&
                                <div className="mx-auto mb-8 p-2 px-4 md:w-1/2 flex flex-col text-center rounded-md bg-gray-200 ring-1 ring-gray-700">
                                    <span className="m-auto text-gray-700 text-sm md:text-base">Produsul nu a ajuns încă pe rafturi sau este dintr-un transport anterior.</span>
                                </div>)

                            }

                        <div className="text-center md:w-1/2 mx-auto">
                            <span className=" text-black font-medium text-md overflow-visible">{obj.title.toUpperCase()}</span>
                        </div>

                        <ImageView images={obj.images}/>

                        <div className="flex flex-col gap-2 mt-8">
                            <div className="mx-auto text-center flex flex-row rounded-md p-4 bg-black text-white w-fit">
                                <span>Preț Stockzilla:&nbsp;</span> 
                                <span className="font-semibold">{(((parseFloat(obj.price) * 5) * 50)/100).toFixed(2)}&nbsp;</span> 
                                <span>RON</span>

                            </div>
                            <div className="mx-auto text-center flex flex-row rounded-md p-4 bg-gray-200 w-fit">
                                <div className="">
                                <span>Preț retail:&nbsp;</span> 
                                <span className="font-semibold">{(parseFloat(obj.price) * 5).toFixed(2)}&nbsp;</span>
                                <span>RON</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col text-justify mx-auto mt-8 w-full md:w-[80%] xl:w-[60%]">
                            <div className="ml-0 text-left text-xl pb-2 border-b-2">
                                <span className="font-bold">Descriere</span>
                            </div>
                            <div className="text-xs md:text-base mt-2">
                                <span className="text-black">{obj.description}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className=" bg-products w-full py-8 h-screen"> 
                    <div className=" m-auto rounded-lg bg-white w-[90%] flex flex-col p-8">
                        <div className="text-center md:w-1/2 mx-auto">
                            <span className=" text-header font-bold text-md overflow-visible text-xl">Ne pare rău, produsul nu a fost găsit.</span>
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <div className=" bg-products w-full py-8"> 
                    <div className=" m-auto rounded-lg bg-white w-[90%] flex flex-col p-8 h-screen">
                        <div className="text-center md:w-1/2 mx-auto w-full py-4 bg-gray-200 rounded-lg animate-pulse">
                            <span className=" text-black  text-md overflow-visible text-md">Se încarcă...</span>
                        </div>
                    </div>
                </div>
        )
    }
}

/* 

<div className=" bg-products w-full py-8"> 
                    <div className=" m-auto rounded-lg bg-white w-[90%] flex flex-col p-8 h-screen">
                        <div className="text-center md:w-1/2 mx-auto w-full py-8 bg-gray-200 rounded-2xl animate-pulse">
                            <span className=" text-black font-bold text-md overflow-visible text-md lg:text-xl">Se încarcă...</span>
                        </div>
                    </div>
                </div>


return (
                <div className=" bg-products w-full py-8 h-screen"> 
                    <div className=" m-auto rounded-lg bg-white w-[90%] flex flex-col p-8">
                        <div className="text-center md:w-1/2 mx-auto">
                            <span className=" text-header font-bold text-md overflow-visible text-xl">Ne pare rău, produsul nu a fost găsit.</span>
                        </div>
                    </div>
                </div>
            )

*/
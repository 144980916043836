import React, {useEffect, useState} from 'react';
import {urlcategory, locationRoot} from '../data/variables';
import CategoryCardGhost from './category-card-ghost';

function CategoryContainer(args) {
    const [categories, loadCategories] = useState([]);
    const [csv, loadCsv] = useState([])
    const [loading, setLoading] = useState(true);


    const getCategories = async () => {
      const res = await fetch(args.categoryPath);
      loadCategories(await res.json());
      const productres = await fetch(args.productPath);
      loadCsv(await productres.json());
      await setLoading(false);
    }

    useEffect(() => {
        getCategories()
      }, [])

      function renderGhosts() {
        const items =[];
            for (let i = 0; i < 10; i++) {
              items.push(CategoryCardGhost());
            }
            return items;
      }

      if(loading) {
        return (<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 2xl:grid-cols-8 gap-2" >
          { renderGhosts()}
        </div>)
        
      } else

    
    return (
    <section className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 gap-2">
    {
      categories.map((category) => {
      if(category !== "") {
        const categoryFormatted = category.replace(/\s+/g,'').replace('&','')
        const categoryCount = csv.filter(item => (item.category.replace(/\s+/g,'').replace('&','') === categoryFormatted)).length;
       // console.log(categoryCount);
        //console.log(categoryFormatted,urlcategory,urlcategory.includes(categoryFormatted))
        if (urlcategory === categoryFormatted) {
            return(
              <a href={locationRoot} className="text-white bg-black p-4 flex flex-row w-full rounded-full duration-200 shadow-lg">
                <span className='m-auto truncate'>{category}&nbsp;</span>
                <span className='ml-auto mr-0'>({categoryCount})</span>
              </a>
            )
        } else {
          return (
            <a href={locationRoot+"/?category="+categoryFormatted} className="duration-200 bg-white shadow-lg rounded-lg p-4 flex flex-row w-full hover:bg-black hover:text-white  hover:rounded-[40px]">
              <span className='m-auto truncate'>{category}&nbsp;</span>
              <span className='ml-auto mr-0'>({categoryCount})</span>
            </a>
          )
        }
      }
    }
    )}
  </section> 
  );
}

export default CategoryContainer;
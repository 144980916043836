import React, {useEffect, useState} from 'react';
import { urlquery, urlpage, urlcategory, filter1, filter2, inMagazin} from '../data/variables';
import ProductCard from './product-card';
import ProductCardGhost from './product-card-ghost';

function ProductContainer(args) {
    
   

    const [csv, loadCsv] = useState([]);
    const [loading, setLoading] = useState(true);

    const getCsv = async () => {
      const res = await fetch(args.productPath);
      loadCsv(await res.json());
      //setLoading(await loadCsv(await res.json()) ? false : true);
      await setLoading(false);
    };

    useEffect(() => {
      getCsv();
    }, []);

    function renderGhosts() {
      const items =[];
          for (let i = 0; i < 60; i++) {
            items.push(ProductCardGhost());
          }
          return items;
    }

    const areplacer1 = new RegExp('ă', 'g');
        const areplacer2 = new RegExp('â', 'g');
        const ireplacer = new RegExp('î', 'g');
        const sreplacer = new RegExp('ș', 'g');
        const treplacer = new RegExp('ț', 'g');

    var filterstatus = 0;
    if(filter1==="1" && filter2==="0")
      filterstatus = 1;
    if(filter1==="0" && filter2==="1")
      filterstatus = 2;
    if(filter1==="1" && filter2==="1")
      filterstatus = 3;

    var magazin = 0;
    if(inMagazin == "1") magazin = 1;
    else magazin = 0;

    if(loading) {
      return (<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-2.5 gap-y-2.5" >
        { renderGhosts()}
      </div>)
      
    }
      
    else {

      if(filterstatus === 0) {
        if (magazin === 0) {
          return (
                  <section className="product-container">
                    <div className="duration-200 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-2.5 gap-y-2.5" >
                        {
                          
                          //csv.filter(item => urlcategory.includes(item.category.replace(/\s+/g,'').replace('&','')) || (urlcategory==="")).map((obj, i) => {

                          csv.filter(item => 
                            (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
                            && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).map((obj, i) => {  
                          if(obj != null) {
                              var show = false;
                              if(i >= (urlpage-1) * 60 && i < urlpage * 60) {
                                show = true;
                              }
                              if(show){
                                    return (ProductCard(obj.id, obj.lpn, obj.title, obj.price, obj.mainImage, obj.stoc, obj.featured));
                                }
                            }
                            return null;
                          })
                        }
                        </div>
                        </section>
                );
        } else {
          return (
            <section className="product-container">
              <div className="duration-200 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-2.5 gap-y-2.5" >
                  {
                    
                    //csv.filter(item => urlcategory.includes(item.category.replace(/\s+/g,'').replace('&','')) || (urlcategory==="")).map((obj, i) => {

                    csv.filter(item => 
                      (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
                      && item.magazin
                      && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).map((obj, i) => {  
                    if(obj != null) {
                        var show = false;
                        if(i >= (urlpage-1) * 60 && i < urlpage * 60) {
                          show = true;
                        }
                        if(show){
                              return (ProductCard(obj.id, obj.lpn, obj.title, obj.price, obj.mainImage, obj.stoc, obj.featured));
                          }
                      }
                      return null;
                    })
                  }
                  </div>
                  </section>
          );
        }
      }
      if(filterstatus === 1) {
        if (magazin === 0) {
          return (
                  <section className="product-container">
                    <div className="duration-200 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-2.5 gap-y-2.5" >
                        {
                          
                          //csv.filter(item => urlcategory.includes(item.category.replace(/\s+/g,'').replace('&','')) || (urlcategory==="")).map((obj, i) => {

                          csv.filter(item => 
                            (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
                            && item.stoc
                            && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).map((obj, i) => {  
                          if(obj != null) {
                              var show = false;
                              if(i >= (urlpage-1) * 60 && i < urlpage * 60) {
                                show = true;
                              }
                              if(show){
                                    return (ProductCard(obj.id, obj.lpn, obj.title, obj.price, obj.mainImage, obj.stoc, obj.featured));
                                }
                            }
                            return null;
                          })
                        }
                        </div>
                        </section>
                );
            } else {
              return (
                <section className="product-container">
                  <div className="duration-200 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-2.5 gap-y-2.5" >
                      {
                        
                        //csv.filter(item => urlcategory.includes(item.category.replace(/\s+/g,'').replace('&','')) || (urlcategory==="")).map((obj, i) => {

                        csv.filter(item => 
                          (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
                          && item.magazin
                          && item.stoc
                          && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).map((obj, i) => {  
                        if(obj != null) {
                            var show = false;
                            if(i >= (urlpage-1) * 60 && i < urlpage * 60) {
                              show = true;
                            }
                            if(show){
                                  return (ProductCard(obj.id, obj.lpn, obj.title, obj.price, obj.mainImage, obj.stoc, obj.featured));
                              }
                          }
                          return null;
                        })
                      }
                      </div>
                      </section>
              );
            }
      }
      if(filterstatus === 2) {
        if(magazin === 0) {
          return (
                  <section className="product-container">
                    <div className="duration-200 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-2.5 gap-y-2.5" >
                        {
                          
                          //csv.filter(item => urlcategory.includes(item.category.replace(/\s+/g,'').replace('&','')) || (urlcategory==="")).map((obj, i) => {

                          csv.filter(item => 
                            (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
                            && item.featured
                            && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).map((obj, i) => {  
                          if(obj != null) {
                              var show = false;
                              if(i >= (urlpage-1) * 60 && i < urlpage * 60) {
                                show = true;
                              }
                              if(show){
                                    return (ProductCard(obj.id, obj.lpn, obj.title, obj.price, obj.mainImage, obj.stoc, obj.featured));
                                }
                            }
                            return null;
                          })
                        }
                        </div>
                        </section>
                );
        } else {
          return (
            <section className="product-container">
              <div className="duration-200 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-2.5 gap-y-2.5" >
                  {
                    
                    //csv.filter(item => urlcategory.includes(item.category.replace(/\s+/g,'').replace('&','')) || (urlcategory==="")).map((obj, i) => {

                    csv.filter(item => 
                      (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
                      && item.featured
                      && item.magazin
                      && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).map((obj, i) => {  
                    if(obj != null) {
                        var show = false;
                        if(i >= (urlpage-1) * 60 && i < urlpage * 60) {
                          show = true;
                        }
                        if(show){
                              return (ProductCard(obj.id, obj.lpn, obj.title, obj.price, obj.mainImage, obj.stoc, obj.featured));
                          }
                      }
                      return null;
                    })
                  }
                  </div>
                  </section>
          );
        }
      }
      if(filterstatus === 3) {
        if(magazin === 0) {
          return (
                  <section className="product-container">
                    <div className="duration-200 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-2.5 gap-y-2.5" >
                        {
                          
                          //csv.filter(item => urlcategory.includes(item.category.replace(/\s+/g,'').replace('&','')) || (urlcategory==="")).map((obj, i) => {

                          csv.filter(item => 
                            (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
                            && item.stoc && item.featured
                            && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).map((obj, i) => {  
                          if(obj != null) {
                              var show = false;
                              if(i >= (urlpage-1) * 60 && i < urlpage * 60) {
                                show = true;
                              }
                              if(show){
                                    return (ProductCard(obj.id, obj.lpn, obj.title, obj.price, obj.mainImage, obj.stoc, obj.featured));
                                }
                            }
                            return null;
                          })
                        }
                        </div>
                        </section>
                );
            } else {
              return (
                <section className="product-container">
                  <div className="duration-200 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-2.5 gap-y-2.5" >
                      {
                        
                        //csv.filter(item => urlcategory.includes(item.category.replace(/\s+/g,'').replace('&','')) || (urlcategory==="")).map((obj, i) => {

                        csv.filter(item => 
                          (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
                          && item.stoc && item.featured
                          && item.magazin
                          && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).map((obj, i) => {  
                        if(obj != null) {
                            var show = false;
                            if(i >= (urlpage-1) * 60 && i < urlpage * 60) {
                              show = true;
                            }
                            if(show){
                                  return (ProductCard(obj.id, obj.lpn, obj.title, obj.price, obj.mainImage, obj.stoc, obj.featured));
                              }
                          }
                          return null;
                        })
                      }
                      </div>
                      </section>
              );
            }
      }

      
    }
}

export default ProductContainer;
import TextLoop from "react-text-loop";

export default function Announcement () {
    return (
        <div className="flex w-full bg-black">
            <div className="flex m-auto p-2 items-center text-center text-sm md:text-base text-white">

                <TextLoop className="" interval={6000} noWrap={false} springConfig={{ stiffness: 180, damping: 8 }} mask={false}>
                    <span>
                        Produsele se pot achiziționa doar fizic din magazinul Stockzilla.
                    </span>
                    <span>
                        Produsele electrice, electronice și electrocasnice beneficiază de 12 luni garanție pe baza bonului fiscal.
                    </span>
                    <span>
                        Magazinul nostru este aprovizionat zilnic cu marfă nouă.
                    </span>
                    <span >
                        Produsele se vând așa cum sunt, în starea în care sunt în magazin.
                    </span>
                    <span >
                        Produsele pot să difere față de imaginile de prezentare de pe site.
                    </span>
                    <span >
                        Produsele pot prezenta urmă de uzură, accesorii lipsă, sau defecte.
                    </span>
                </TextLoop>
            </div>
        </div>
    )
}
import CategoryContainer from './category-container';
import SearchBar from './search-bar';

export default function SearchCategory (args) {
    return (
        <div className=" bg-category">
            <div className='m-auto md:w-[90%] lg:w-[80%] text-sm md:text-base md:px-0 px-8 py-4'>
                <div className='flex flex-col gap-4'>
                    <SearchBar/>
                    <CategoryContainer productPath={args.productPath} categoryPath={args.categoryPath}/>
                </div>
            </div>
        </div>
    )
}
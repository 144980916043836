import React, {useRef, useState} from 'react';
import { locationRoot, urlcategory } from '../data/variables';

function SearchBar () {
    const [query, setQuery] = useState('');
    const queryRef = useRef(query);

    const handleChange = event => {
        queryRef.current = event.target.value; 
        setQuery(event.target.value);
      };
  
      const keyDownHandler = event => {
        const areplacer1 = new RegExp('ă', 'g');
        const areplacer2 = new RegExp('â', 'g');
        const ireplacer = new RegExp('î', 'g');
        const sreplacer = new RegExp('ș', 'g');
        const treplacer = new RegExp('ț', 'g');
        if (event.key === 'Enter') {
          event.preventDefault();
          if (queryRef.current !== "") {
            window.location.href = locationRoot+"/?category="+"&page="+1+"&search="+queryRef.current.replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t");
          }
        }
      }

    return (
        <section className="flex w-full">
              <div className="mx-auto flex flex-row w-full md:w-3/4 lg:w-1/3">
                <input class="p-4 w-3/4 duration-100 rounded-l-lg border-none focus:ring-0 hover:shadow-md peer-[]:"
                  onKeyDown={keyDownHandler}
                  type="text"
                  placeholder="Caută..."
                  onChange={handleChange}
                  value={query}
                  />
                  <a href={locationRoot+"/?category="+"&page="+1+"&search="+query}
                  class="w-1/4 peer-hover:shadow-md flex ml-0 p-2 bg-gray-200 rounded-r-lg hover:rounded-r-[50px] text-black hover:text-white hover:bg-black duration-200">
                    <span className=" m-auto my-auto">Caută</span>
                  </a>
              </div>
              
            </section>
    );
}

export default SearchBar;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './tailwind.css';
import Main from './Main';
import Product from './Product';
import Informatii from './Informatii';
import Contact from './Contact';
import DeCeRetururi from './DeCeRetururi';
import "@fontsource/montserrat";

import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <Routes>
        <Route path='/' element={<Main productPath="/products.php" categoryPath="/categories.php"/>} />
        <Route path='/product' element={<Product productPath="/products.php"/>} />
        <Route path='/cevacodsecret' element={<Main productPath="/products1.php" categoryPath="/categories1.php"/>} />
        <Route path='/cevacodsecret/product' element={<Product productPath="/products1.php"/>} />
        <Route path='/altcodsecret' element={<Main productPath="/products2.php" categoryPath="/categories2.php"/>} />
        <Route path='/altcodsecret/product' element={<Product productPath="/products2.php"/>} />
        <Route path='/unde-ne-gasesti' element={<Contact/>} />
        <Route path='/de-ce-retururi' element={<DeCeRetururi/>} />
        <Route path='/informatii-importante' element={<Informatii/>} />
    </Routes>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const textRetururi = [
    {
        title:"Reduci impactul negativ asupra mediului",
        description:"Într-o lume care se confruntă cu o mulțime de provocări în ceea ce privește poluarea mediului, fiecare gest contează. Când un client face un retur, fie pentru că produsul are un mic defect sau doar pentru că s-a răzgândit, de multe ori, acel obiect ajunge să fie transformat in deșeu. Cumpărând retururi, creștem durata de viață a acestor produse și astfel scădem cererea de producție pentru produse noi, scăzând astfel consumul de apă și de energie necesare în procesul de producție.",
    },
    {
        title:"Reduci emisia de gaze de seră",
        description:"În procesul de producție și de transport al produselor noi se emite o cantitate mare de gaze de seră. Cumpărând produse din retururi, scădem nevoia de a produce bunuri noi, ceea ce duce la diminuarea poluării apărute în procesul de producție. În plus, transportarea lor către magazine și apoi către consummator, înseamnă creșterea emisiilor de carbon în atmosferă.",
    },
    {
        title:"Încurajezi sustenabilitatea",
        description:"Cumpărând produse provenite din retururi, transmiți magazinelor și producătorilor că te interesează consumul sustenabil.",
    },
    {
        title:"Ai acces la produsele marilor branduri la prețuri mici",
        description:"Faptul că economisești bani, este unul dintre cele mai importante beneficii atunci când cumperi retururi.",
    },
    {
        title:"Diversitatea produselor",
        description:"În magazinul nostru vei găsi produse dintre cele mei diverse: electronice, electrocasnice, mic mobilier, produse pentru îngrijirea copiilor, produse pentru animale, papetărie, birotică, produse pentru grădinărit, haine, decorațiuni și multe altele.",
    },
]

export {textRetururi};
import React, {useEffect, useState} from 'react';
import { urlcategory, urlquery, urlpage, locationRoot, filter1, filter2, inMagazin } from "../data/variables";
import PageCard from './pagination/page-card';
import PageCardDisabled from './pagination/page-card-disabled';
import PageCardSelected from './pagination/page-card-selected';
import PageCardGhost from './page-card-ghost';

var lastpage;

function PageContainerUp (args) {
    const [csv, loadCsv] = useState([]);
    const [loading, setLoading] = useState(true);

    const getCsv = async () => {
      const res = await fetch(args.productPath);
      loadCsv(await res.json());
      await setLoading(false);
    };

    useEffect(() => {
      getCsv();
    }, []);

    function renderGhosts() {
      const items =[];
          for (let i = 0; i < 7; i++) {
            items.push(PageCardGhost());
          }
          return items;
    }

    const areplacer1 = new RegExp('ă', 'g');
        const areplacer2 = new RegExp('â', 'g');
        const ireplacer = new RegExp('î', 'g');
        const sreplacer = new RegExp('ș', 'g');
        const treplacer = new RegExp('ț', 'g');

        var filterstatus = 0;
        if(filter1==="1" && filter2==="0")
          filterstatus = 1;
        if(filter1==="0" && filter2==="1")
          filterstatus = 2;
        if(filter1==="1" && filter2==="1")
          filterstatus = 3;
        var magazin = 0;
        if(inMagazin == "1") magazin = 1;
        else magazin = 0; 


    function renderPages() {
        var number = 0; 
        
        if (filterstatus===0) {
          if (magazin === 0) {
        number = csv.filter(item => 
          (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
          && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).length;
          } else {
            number = csv.filter(item => 
              (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
              && item.magazin
              && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).length;
              
          }
        }
        if (filterstatus===1) {
          if (magazin === 0) {
          number = csv.filter(item => 
            (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
            && item.stoc
            && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).length;
          } else {
            number = csv.filter(item => 
            (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
            && item.stoc
            && item.magazin
            && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).length;
          }
        }
        if (filterstatus===2) {
          if (magazin === 0) {
          number = csv.filter(item => 
            (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
            && item.featured
            && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).length;
          } else {
            number = csv.filter(item => 
              (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
              && item.featured
              && item.magazin
              && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).length;
          
          }
        }  
        if (filterstatus===3) {
          if (magazin === 0) {
          number = csv.filter(item => 
            (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
            && item.stoc && item.featured
            && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).length;
          } else {
            number = csv.filter(item => 
              (urlcategory === item.category.replace(/\s+/g,'').replace('&','') || (urlcategory===""))
              && item.stoc && item.featured
              && item.magazin
              && item.title.toLowerCase().replace(areplacer1,"a").replace(areplacer2,"a").replace(ireplacer,"i").replace(sreplacer,"s").replace(treplacer,"t").includes(urlquery.toLowerCase())).length;
          
          }
        }

          lastpage = (parseInt(number / 60 + Math.min(1, number % 60)));

        //console.log(urlpage, lastpage);
        var pagearr = [];
        if (urlpage === 1) {
          pagearr.push(<PageCardDisabled text="<<" href="" />)
          pagearr.push(<PageCardDisabled text="<" href="" />);
          if (lastpage !== 0) {
            pagearr.push(<PageCardSelected href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={urlpage}/>)
            for(var i = 2; i <= Math.min(3,lastpage); i++) {
              pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(i)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={i}/>);
            }
            if(urlpage === lastpage) {
              pagearr.push(<PageCardGhost/>)
              pagearr.push(<PageCardGhost/>)
              pagearr.push(<PageCardDisabled text=">"/>);
              pagearr.push(<PageCardDisabled text=">>"/>);
            }
            else if(urlpage === lastpage-1) {
              pagearr.push(<PageCardGhost/>)
              pagearr.push(<PageCardDisabled text=">"/>);
              pagearr.push(<PageCardDisabled text=">>"/>);
            }
            else {
              pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage+1)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text=">"/>);
              pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+lastpage+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text=">>"/>);
            }
          } else {
              pagearr.push(<PageCardGhost/>)
              pagearr.push(<PageCardGhost/>)
              pagearr.push(<PageCardGhost/>)
              pagearr.push(<PageCardDisabled text=">"/>);
              pagearr.push(<PageCardDisabled text=">>"/>);
          }
          return pagearr;
        }
        if (urlpage === 2) {
          pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page=1&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text="<<"/>);
          pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage-1)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text="<"/>);     
          pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage-1)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={urlpage-1}/>);
          pagearr.push(<PageCardSelected href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={urlpage}/>);
          for(i = 3; i <= Math.min(3,lastpage); i++) {
            pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(i)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={i}/>);
          }
          if(urlpage === lastpage) {
            pagearr.push(<PageCardGhost/>)
            pagearr.push(<PageCardDisabled text=">"/>);
            pagearr.push(<PageCardDisabled text=">>"/>);
          }
          else {
            pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage+1)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text=">"/>);
            pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+lastpage+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text=">>"/>);
          }
          return pagearr;
        }
        if (urlpage !== lastpage) {
          pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page=1&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={"<<"}/>);
          pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage-1)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={"<"}/>);     
          pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage-1)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={urlpage-1}/>);
          pagearr.push(<PageCardSelected href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={urlpage}/>);
          for(i = urlpage+1; i <= Math.min(urlpage+1,lastpage); i++) {
            pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(i)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={i}/>);
          }
        }
          if(urlpage === lastpage) {
            pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page=1&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={"<<"}/>);
            pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage-1)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={"<"}/>);     
            pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage-1)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={urlpage-2}/>);
            pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage-1)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={urlpage-1}/>);
            pagearr.push(<PageCardSelected href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={urlpage}/>);
            
            pagearr.push(<PageCardDisabled text={">"}/>);
            pagearr.push(<PageCardDisabled text={">>"}/>);
          } else {
            pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+(urlpage+1)+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={">"}/>);
            pagearr.push(<PageCard href={locationRoot+"/?category="+urlcategory+"&page="+lastpage+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin="+inMagazin} text={">>"}/>);
          }
          return pagearr;
        }

        if(loading) {
          return (<div className="grid grid-cols-7 gap-2 w-full sm:w-[90%] md:w-3/4 lg:w-1/2 2xl:w-1/3 mx-auto" >
            { renderGhosts()}
          </div>)
          
        } else

        return (
            <div className=" grid grid-cols-7 gap-2 w-full sm:w-[90%] md:w-3/4 lg:w-1/2 2xl:w-1/3 mx-auto">

            {renderPages()}
            </div>
        )
}

export default PageContainerUp;
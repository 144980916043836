
function ProductCardGhost () {
        return (
          <div class="flex flex-col w-full gap-5 p-6 mx-auto bg-white shadow-lg select-none rounded-2xl ">
            <div class="w-full h-8 m-auto bg-gray-200 rounded-md animate-pulse"></div>
            <div class="bg-gray-200 w-full aspect-square rounded-xl animate-pulse m-auto"></div>
            <div class="flex flex-col flex-1 gap-5">
              <div class="flex flex-col flex-1 gap-2">
                <div class="w-full m-auto bg-gray-200 animate-pulse h-6 rounded-2xl"></div>
                <div class="w-28 h-4 m-auto bg-gray-200 rounded-full animate-pulse"></div>
                <div class="w-28 h-4 m-auto bg-gray-200 rounded-full animate-pulse"></div>
              </div>
            </div>
          </div>
        );
}

export default ProductCardGhost;
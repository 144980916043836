import { useState } from "react"
import { UilArrowCircleDown } from '@iconscout/react-unicons'

export default function AccordionRetururi (args) {
    const [accOpen, setAccOpen] = useState(false);
    return (
        <div className="text-sm md:text-base">
            <button className="font-semibold p-4 bg-gray-200 hover:bg-gray-300 rounded-lg flex justify-between w-full"
            onClick={() => {setAccOpen(!accOpen)}}>
                
                <span>{args.title}</span>
                <div className={`duration-200 ${accOpen ? 'rotate-180' : ''}`}><UilArrowCircleDown/></div>
            </button>
            <div className={` grid overflow-hidden transition-all duration-200 ease-in-out text-black ${
                accOpen ? 'py-4 grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
            }`}>
                <div className="overflow-hidden">
                    {args.description}
                </div>
            </div>
        </div>
    )
}
import PageContainerUp from "./page-container-up"
import ProductContainer from "./product-container"
import { urlcategory, urlquery, urlpage, filter1, filter2, inMagazin, locationRoot } from "../data/variables";

export default function PagesProducts (args) {
    return (
        <div className=" bg-products">
            <div className='m-auto md:w-3/4 text-sm md:text-base px-8 sm:px-0 pt-4'>
                <div className='flex flex-col gap-2'>

                

                    <PageContainerUp productPath={args.productPath}/>

                    <div className="flex flex-row gap-2 w-full sm:w-[90%] md:w-3/4 lg:w-1/2 2xl:w-1/3 mx-auto">
                            {inMagazin=="1" && 
                                <a href={locationRoot+"/?category="+urlcategory+"&page=1"+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin=0"}
                                    className=" shadow-lg flex w-full p-4 text-center items-center rounded-lg bg-black text-white duration-200">
                                        <span className="m-auto text-center">Disponibile în magazin</span>
                                    </a>
                            }

                            {inMagazin=="0" && 
                                <a href={locationRoot+"/?category="+urlcategory+"&page=1"+"&search="+urlquery+"&filter1="+filter1+"&filter2="+filter2+"&inMagazin=1"}
                                className=" hover:bg-black hover:text-white shadow-lg flex w-full p-4 text-center items-center rounded-lg bg-white text-black duration-200">
                                    <span className="m-auto text-center">Disponibile în magazin</span>
                                </a>
                            }
                            
                        </div>
                    
                    <div className="flex flex-col gap-2">

                        <div className="flex flex-row gap-2 w-full sm:w-[90%] md:w-3/4 lg:w-1/2 2xl:w-1/3 mx-auto">
                        
                            {(filter1!=="1" && 
                            
                            <a href={locationRoot+"/?category="+urlcategory+"&page=1"+"&search="+urlquery+"&filter1=1"+"&filter2="+filter2+"&inMagazin="+inMagazin}
                            className=" shadow-lg flex w-full p-2 text-center items-center rounded-lg bg-white text-black hover:bg-black hover:text-white duration-200">
                                <span className="m-auto text-center">În Stoc</span>
                            </a>)
                            
                            ||
                            
                            <a href={locationRoot+"/?category="+urlcategory+"&page=1"+"&search="+urlquery+"&filter1=0"+"&filter2="+filter2+"&inMagazin="+inMagazin}
                            className=" shadow-lg flex w-full p-2 text-center items-center rounded-lg bg-black text-white duration-200">
                                <span className="m-auto text-center">În Stoc</span>
                            </a>}

                            {(filter2!=="1" && 

                            <a href={locationRoot+"/?category="+urlcategory+"&page=1"+"&search="+urlquery+"&filter1="+filter1+"&filter2=1"+"&inMagazin="+inMagazin}
                            className=" shadow-lg flex w-full p-4 text-center items-center rounded-lg bg-white text-black hover:bg-black hover:text-white duration-200">
                                <span className="m-auto text-center">Adăugate recent</span>
                            </a>)
                            
                            ||

                            <a href={locationRoot+"/?category="+urlcategory+"&page=1"+"&search="+urlquery+"&filter1="+filter1+"&filter2=0"+"&inMagazin="+inMagazin}
                            className=" shadow-lg flex w-full p-4 text-center items-center rounded-lg bg-black text-white duration-200">
                                <span className="m-auto text-center">Adăugate recent</span>
                            </a>
                            
                            }
                        </div>
                        
                        
                    </div>
                    
                    
                </div>
            </div>
            <div className='m-auto md:w-[90%] lg:w-[80%] text-sm md:text-base md:px-0 px-8 pt-4'>
                <div className='flex flex-col gap-4'>
                    
                    <ProductContainer productPath={args.productPath}/>
                    
                    
                </div>
            </div>
            <div className='m-auto md:w-3/4 text-sm md:text-base sm:px-0 px-8 pt-4 pb-8'>
                <div className='flex flex-col gap-4'>
                    
                    <PageContainerUp productPath={args.productPath}/>
                    
                </div>
            </div>
            
        </div>
    )
}
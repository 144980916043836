import { useState } from "react";

export default function ImageView(obj) {

    const [mainImage, setMainImage] = useState(obj.images[0].large);

    return (
        <div className="mx-auto flex flex-col gap-8 sm:w-[70%] md:w-[50%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%]">
            <div className="relative items-center align-middle text-center mx-auto mt-8 p-8 ring-offset-8 ring-1 ring-gray-300 rounded-2xl">
                <div className="flex items-center justify-center">
                    <img className=" object-contain" src={mainImage}/>
                </div>
                <div className="absolute top-0 right-0 rounded-full text-xs py-2 px-4 bg-black text-white"> -50% </div>
            </div>
            <div className="mx-auto grid grid-cols-6 gap-2 flex-wrap">
                {obj.images.map((image) => {
                    if(mainImage === image.large) {
                        return (
                            <div className="duration-200 aspect-square rounded-md outline-2 outline outline-black p-2">
                                <img className="object-contain" src={image.thumb}/>
                            </div>
                        )
                    }
                    else {
                        return (
                            <button 
                            className="duration-200 aspect-square rounded-md outline-1 outline outline-gray-400 p-2 hover:outline-black"
                            onClick={()=> {setMainImage(image.large)}}
                            >
                                <img className="object-contain" src={image.thumb}/>

                            </button>
                        )
                    }
                })}
            </div>
        </div>
    );
}